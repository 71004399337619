<template>
  <HandleDataForms v-bind:apiSource="mongo" v-bind:dataSource="'/'+database" title="Formulario Simulador Puntaje" collection="eloqua_simulador_puntaje" templateTable="TableEventosForm" templateTableModal="TableEventosModal" v-bind:dataExcel="dataExcel" />
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import moment from 'moment'
import HandleDataForms from '@/components/HandleDataForms.vue'

export default {
  components: {
    HandleDataForms,
  },
  setup() {
    const store = useStore()
    const mongo = computed(() => store.getters.getMongoURL)
    const database = computed(() => store.getters.getDBDashboard)
    const startDate = computed(() => moment().startOf('week').format('YYYY-MM-DD'))
    const endDate = computed(() => moment().format('YYYY-MM-DD'))

    let dataExcel = {
      fields: {
        createdAt: 'Fecha',
        ChannelType_c: 'Canal',
        TipoDeLead_c: 'Tipo de lead',
        TipoDeOrigen_c: 'Tipo de origen',
        TipoDePrograma_c: 'Tipo de programa',
        UnidadDeNegocio_c: 'Unidad de negocio',
        PrimaryContactPersonFirstName: 'Nombre',
        PrimaryContactPersonLastName: 'Apellido paterno',
        ApellidoMaterno_c: 'Apellido materno',
        RUToRUTProvisionalDeContacto_c: 'Número de documento',
        DNIoPasaporte_c: 'DNI o Pasaporte',
        TipoDeCorreo_c: 'Tipo de correo',
        PrimaryContactEmailAddress: 'E-mail',
        FormularioDeOrigen_c: 'Formulario de origen',
        CodigoDEMREDePrograma_c: 'Código DEMRE de programa',
        Name: 'Carrera',
        Carrera_c: 'Código carrera',

        ponderacion: { ciencias: 'Ponderacion Ciencias', historia: 'Ponderacion Historia', lenguaje: 'Ponderacion Lenguaje', matematicas: 'Ponderacion Matemáticas', notas: 'Ponderacion Notas', ranking: 'Ponderacion Ranking', matematicas_2: 'Ponderacion Matemáticas 2' },
        puntaje: { nem: 'Puntaje NEM', ranking: 'Puntaje Ranking', ciencias: 'Puntaje Ciencias', historia: 'Puntaje Historia', lenguaje: 'Puntaje Lenguaje', matematicas: 'Puntaje Matemáticas', notas: 'Puntaje Notas', matematicas_2: 'Puntaje Matemáticas 2' },

        Sede_c_name: 'Sede',
        ZonaDeAdmision_c: 'Zona de admisión',
        PaisDeOrigen_c: 'País de origen',
        Nacionalidad_c: 'Nacionalidad',
        PrimaryContactState: 'Región',
        Comuna_c: 'Comuna',
        Modalidad_c: 'Modalidad',
        Jornada_c: 'Jornada',
        TipoDeTelefono_c: 'Tipo de teléfono',
        PrimaryPhoneCountryCode: 'Código celular',
        PrimaryPhoneAreaCode: 'Código teléfono',
        PrimaryPhoneNumber: 'Número celular',
        utm_source: 'UTM Source',
        utm_medium: 'UTM Medium',
        utm_campaign: 'UTM Campaign',
        historial: 'Historial',
      },
      query: {
        collection: 'eloqua_simulador_puntaje',
        date_field: 'createdAt',
        fecha_inicio: startDate.value + ' 00:00:00',
        fecha_fin: endDate.value + ' 23:59:59',
      },
    }

    return {
      mongo,
      dataExcel,
      database
    }
  },
}
</script>

// ponderacion: { ciencias: 'Ciencias', historia: 'Historia', lenguaje: 'Lenguaje', matematicas: 'Matemáticas', notas: 'Notas', ranking: 'Ranking', matematicas_2: 'Matemáticas 2' } ,

  "ponderacion": {
    "ciencias": "20",
    "historia": "20",
    "lenguaje": "40",
    "matematicas": "20",
    "notas": "10",
    "ranking": "10",
    "matematicas_2": "0"
  },
  "puntaje": {
    "nem": "800",
    "ranking": "900",
    "ciencias": "200",
    "historia": "",
    "lenguaje": "800",
    "matematicas": "800",
    "notas": 0,
    "matematicas_2": 0
  },
}